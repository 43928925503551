import {submitEvent} from "../../../webpack/modules/events";
import {ga4TrackSimpleEvent} from "../../../webpack/modules/analytics";
import {makeUrl} from "../../../webpack/modules/url";

submitEvent('.search-form', false, function(form, event) {
    event.preventDefault();
    let search = form.querySelector('input').value;
    let url = form.querySelector('.all-products-url').value;
    let params = {'q': search};
    let eventPayload = {
        event: 'searcher',
        search_term: search,
        method: 'new-search',
        engine: 'algolia'
    };
    let searchEventPayload = {
        event: 'search',
        search_term: search
    };

    ga4TrackSimpleEvent(eventPayload);
    ga4TrackSimpleEvent(searchEventPayload);
    window.location.href = encodeURI(makeUrl('/'+url+'/search', params));
});
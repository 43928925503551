import {
    changeEvent,
    clickEvent,
    customDocumentEvent, dispatchCustomEvent,
    fireChangeEvent
} from "../../../../webpack/modules/events";
import {
    getFilters, initCombinationSuggestions, initProductImageSliders, updateExclusivePremiumProducts,
    updateList
} from "../../../../webpack/modules-optimized/product-list";
import {closeLoadingModal, openLoadingModal} from "../../../../webpack/modules-optimized/modal";
import {updateVisibilityDescription} from "../../../../webpack/modules/description";
import {initBreadcrumb} from "../../../../webpack/modules/breadcrumb";
import {ga4TrackSimpleEvent, itemDatalayerPush} from "../../../../webpack/modules/analytics";
import {onVisible} from "../../../../scripts/Tools/visible-element-observer";
import {isMobile} from "../../../../webpack/modules/devices";
import {initLastViewedProductsRecommender} from "../../../../webpack/modules-optimized/last-viewed-products";

const listType = document.querySelector('.product-list.search-results') ? 'search_results' : 'product_list';

changeEvent('#product-list-page input[name="filter"]', true, async function(checkbox) {
    syncFilters(checkbox);
    loadResults(checkbox.dataset.url);
});

customDocumentEvent('productListFiltersChanged', function(event) {
    let url = event.detail.url;
    loadResults(url);
});

clickEvent('#product-list-page .filter-link', true, function(element, event) {
    event.preventDefault();
    let filter = element.closest('.filter').querySelector('input[name="filter"]');
    filter.checked = !filter.checked;
    fireChangeEvent(filter, true);
});

clickEvent('.filter-label', true, function(element) {
    let filterId = element.dataset.id;
    let filterName = element.dataset.name;
    let checkbox = document.querySelector(`#product-list-page input[name="filter"][value="${filterId}"]`);

    checkbox.checked = false;
    fireChangeEvent(checkbox, true);
    ga4TrackSimpleEvent({
        'event': 'filter_' + listType,
        'method': 'delete_filter',
        'content_type': filterName
    });
});

clickEvent('.clear-filters', true, function(button) {
    document.querySelectorAll('#product-list-page input[name="filter"]:checked').forEach(function(checkbox) {
        checkbox.checked = false;
        updatePillButtons(checkbox);
        updateColorButtons(checkbox);
    });
    loadResults(button.dataset.url);
    ga4TrackSimpleEvent({
        'event': 'filter_' + listType,
        'method': 'delete_filter',
        'content_type': 'all'
    });
});

function syncFilters(originalFilter)
{
    let filters = document.querySelectorAll(`#product-list-page input[name="filter"][value="${originalFilter.value}"]`);

    filters.forEach(function(filter) {
        filter.checked = originalFilter.checked;
        updatePillButtons(filter);
        updateColorButtons(filter);

        if (filter.classList.contains('child')) {
            let parents = document.querySelectorAll(`#product-list-page input[name="filter"][value="${filter.dataset.parent}"]`);
            parents.forEach((parent) => parent.checked = false);
        } else if (filter.classList.contains('parent')) {
            let children = document.querySelectorAll(`#product-list-page input[name="filter"][data-parent="${filter.value}"]`);
            children.forEach((child) => child.checked = false);
        }
    });
}

async function loadResults(url)
{
    let type = document.querySelector('#type').value;
    let query = document.querySelector('#query-string').value;
    let imageDisplay = document.querySelector('#product-list-page .image-display-selector:checked').value;
    let filters = getFilters(document.querySelector('#product-list-page'));
    let buttonsClear = document.querySelectorAll('#product-list-page .clear-filters');
    let buttonsView = document.querySelectorAll('#product-list-page button.view-products');

    openLoadingModal();

    try {
        let data = await updateList(type, filters, query, url, imageDisplay);
        if (!data) {
            closeLoadingModal();
            return;
        }

        if (data.redirect) {
            window.location.href = data.redirect;
            return;
        }

        if (!data.count) {
            closeLoadingModal();
            return;
        }

        let newProductsFilter = data.newProductsFilter;
        let newsFilter = document.querySelector('#product-list-page #newProducts');
        if (newProductsFilter && newsFilter) {
            let newsContainer = newsFilter.closest('.newProducts');
            let selectedFilters = document.querySelector('#selected-filters-labels');

            if (!newProductsFilter.count) {
                newsContainer.classList.add('d-none');
                if (selectedFilters.classList.contains('col-12')) {
                    selectedFilters.classList.remove('col-12');
                    selectedFilters.classList.add('col-auto');
                }
            }
            else {
                newsContainer.classList.remove('d-none');
                newsFilter.dataset.url = newProductsFilter.url;
                if (selectedFilters.classList.contains('col-auto')) {
                    selectedFilters.classList.remove('col-auto');
                    selectedFilters.classList.add('col-12');
                }
            }
        }

        let lastViewedContainer = document.querySelector('#last-viewed-products-container.top-last-viewed-products-container');
        if (lastViewedContainer) {
            lastViewedContainer.remove();
        }

        document.querySelector('#product-list-page #list-products').innerHTML = data.productsBlock;
        document.querySelector('#product-list-page #title').innerHTML = data.title;
        document.querySelectorAll('#product-list-page .filters-list:not(#filters-pill)').forEach((filter) => filter.parentElement.innerHTML = data.filtersBlock);
        if (data.isSearchAllTypes && isMobile()) document.querySelector('#product-list-page #list-filters').innerHTML = data.inlineProductTypesBlock;
        document.querySelector('#product-list-page #reviews').innerHTML = data.reviewsBlock;
        let descriptionContainer = document.querySelector('#product-list-page .description-container');
        if (data.oldDescription && descriptionContainer) descriptionContainer.innerHTML = data.descriptionBlock;
        if (document.querySelector('#product-list-page #number-of-products')) document.querySelector('#product-list-page #number-of-products').innerHTML = data.count;
        if (document.querySelector('#product-list-page #number-of-products-type-name')) document.querySelector('#product-list-page #number-of-products-type-name').innerHTML = data.typeName.toLowerCase();
        if (document.querySelector('#breadcrumb')) document.querySelector('#breadcrumb').innerHTML = data.breadcrumb;
        if (document.querySelector('#product-list-page #selected-filters-labels') && !data.isSearchAllTypes) document.querySelector('#product-list-page #selected-filters-labels').innerHTML = data.selectedFiltersLabelsBlock;
        if (document.querySelector('#product-list-page #list-combination-suggestions')) document.querySelector('#product-list-page #list-combination-suggestions').innerHTML = data.combinationSuggestionsBlock;

        let categoryCombinationSuggestions = document.querySelector('#product-list-page #list-combination-suggestions');
        if (categoryCombinationSuggestions) {
            if (data.combinationSuggestionsBlock) document.querySelector('#product-list-page #list-combination-suggestions').classList.remove('d-none');
            else document.querySelector('#product-list-page #list-combination-suggestions').classList.add('d-none');
        }

        let selectedFilters = document.querySelectorAll('#product-list-page input[name="filter"]:checked');
        let obj = {title: data.title, url: url};

        if (data.isSearchAllTypes) {
            let filtersActive = 0;

            selectedFilters.forEach(function(filter) {
                if (filter.value !== '') filtersActive++;
            });

            if (filtersActive) buttonsClear.forEach((buttonClear) => buttonClear.classList.remove('disabled'));
            else buttonsClear.forEach(function (buttonClear) {
                if (!buttonClear.classList.contains('disabled')) buttonClear.classList.add('disabled');
            });
        } else {
            if (selectedFilters.length) buttonsClear.forEach((buttonClear) => buttonClear.classList.remove('disabled'));
            else buttonsClear.forEach(function (buttonClear) {
                if (!buttonClear.classList.contains('disabled')) buttonClear.classList.add('disabled');
            });
        }

        buttonsView.forEach((buttonView) => buttonView.querySelector('.text').innerHTML = buttonView.dataset.originalText.replace('%count%', data.count));

        let filtersMobile = document.querySelector('#product-list-page #filters-mobile .filter-list');
        if (filtersMobile) {
            filtersMobile.querySelectorAll('.filter-type .ft-container').forEach(function(item) {
                let collapse = item.querySelector('.cbox');
                let button = item.querySelector('.button');
                if (collapse && button) {
                    let object = Collapse.getOrCreateInstance(collapse);
                    object.hide();
                    button.setAttribute('aria-expanded', 'false');
                    button.classList.add('collapsed');
                }
            });
        }
        
        let container = document.querySelector('#last-viewed-products-container');
        if (container) initLastViewedProductsRecommender(container);
        initProductImageSliders(document.querySelector('#product-list-page'), imageDisplay);
        updateVisibilityDescription(document.querySelectorAll('#product-list-page .description-container .description'));
        initBreadcrumb();
        initCombinationSuggestions(document.querySelector('.combination-suggestions'));
        updateExclusivePremiumProducts(document.querySelector('#product-list-page #list-products'));

        setTimeout(() => closeLoadingModal(), 400);
        history.pushState(obj, obj.title, obj.url);
        onVisible(document.querySelectorAll(".fire-product-datalayer"), (element) => itemDatalayerPush('view_item_list', element));
        if (data.ecData) eval(data.ecData);
        if (data.fbData) eval(data.fbData);
        dispatchCustomEvent('productListUpdated', {isLastPage: data.isLastPage});
    } catch (error) {
        closeLoadingModal();
    }
}

function updatePillButtons(filter)
{
    let button = filter.closest('label.btn.filter');

    if (!button) return;

    let icon = button.querySelector('i');

    if (filter.checked) {
        button.classList.remove('btn-outline-purple');
        button.classList.add('btn-purple');
        icon.classList.remove('d-none');
    } else {
        button.classList.remove('btn-purple');
        button.classList.add('btn-outline-purple');
        icon.classList.add('d-none');
    }
}

function updateColorButtons(filter)
{
    if (!filter.closest('.filters-list')) return;
    let colorContainer = filter.closest('.filters-list').querySelector(`label.color-container[for="${filter.id}"]`);

    if (!colorContainer) return;

    let color = colorContainer.querySelector('.color');
    let icon = colorContainer.querySelector('i');

    if (filter.checked) {
        color.classList.remove('border-gray');
        color.classList.add('border-2', 'border-dark');
        icon.classList.add('selected');
    } else {
        color.classList.remove('border-2', 'border-dark');
        icon.classList.remove('selected');
        color.classList.add('border-gray');
    }
}
import {isDesktop} from "./devices";
import {checkElementIsVisibleInParent} from "../../scripts/Tools/visible-element-observer";

export function updateVisibilityDescription(descriptions)
{
    descriptions.forEach(function(description) {
        let buttons = description.closest('.description-container').querySelectorAll('button.change-description-visibility');
        if (!buttons) return;
        let height = description.scrollHeight;
        let lineHeight = window.getComputedStyle(description).getPropertyValue("line-height");
        lineHeight = parseFloat(lineHeight);
        let maxLines = description.dataset.maxLines;
        let heightClosedDescription = lineHeight * maxLines;

        if (description.classList.contains('only-mobile') && isDesktop()) {
            buttons.forEach((button) => button.classList.add('d-none'));
            description.classList.remove('description-closed');
            return;
        }

        if (height > heightClosedDescription && (height - heightClosedDescription) > 1) {
            buttons.forEach((button) => button.classList.remove('d-none'));
            description.classList.add('description-closed');

            let descriptionCheck = description.querySelector('#allDescVis');
            if (descriptionCheck) {
                if (checkElementIsVisibleInParent(descriptionCheck)) {
                    buttons.forEach((button) => button.classList.add('d-none'));
                    description.classList.remove('description-closed');
                }
            }
        } else {
            buttons.forEach((button) => button.classList.add('d-none'));
            description.classList.remove('description-closed');
        }
    });
}

export function toggleDescriptionVisibility(container)
{
    let description = container.querySelector('.description');
    let isClosed = description.classList.contains('description-closed');
    let buttons = container.querySelectorAll('.change-description-visibility');
    let button = container.querySelector('button');
    let viewMoreText = button.dataset.viewMoreText;
    let viewLessText = button.dataset.viewLessText;
    let descriptionIcon = container.querySelector('.description-title .icon');

    if (isClosed) {
        description.classList.remove('description-closed');
        if (descriptionIcon) {
            descriptionIcon.classList.remove('fa-chevron-down');
            descriptionIcon.classList.add('fa-chevron-up');
        } else {
            buttons.forEach((button) => button.innerHTML = button.dataset.viewLessText);
        }
        button.innerHTML = viewLessText;
        setTimeout(() => {description.style.maxHeight = description.scrollHeight+'px'}, 500);
    } else {
        description.classList.add('description-closed');
        if (descriptionIcon) {
            descriptionIcon.classList.remove('fa-chevron-up');
            descriptionIcon.classList.add('fa-chevron-down');
        } else {
            buttons.forEach((button) => button.innerHTML = button.dataset.viewMoreText);
        }
        button.innerHTML = viewMoreText;
        description.style.maxHeight = '';
    }
}
import {getCookie} from "../../../webpack/modules-optimized/cookies";
import {getUserCountryData, showCountrySelectPopUp} from "../../../webpack/modules-optimized/country";
import {dispatchCustomEvent} from "../../../webpack/modules/events";

let userCountry = sessionStorage.getItem('_tv_user_country');
let i18nIdentified = getCookie('_tv_i18n_identified');
let cookieChoice = getCookie('_tv_cookie_choice');

if (!i18nIdentified) {
    if (!userCountry) loadUserCountryData();
    else if (cookieChoice) showCountrySelectPopUp(userCountry);
}

async function loadUserCountryData() {
    let data = await getUserCountryData();
    sessionStorage.setItem('_tv_user_cookie_country', data.cookieCountry);
    if (data.countryCode) sessionStorage.setItem('_tv_user_country', data.countryCode);
    dispatchCustomEvent('userCountryDataLoaded', data);
}
import {clickEvent} from "../../../webpack/modules/events";
import {ga4TrackSimpleEvent} from "../../../webpack/modules/analytics";
import {slideUp} from "../../../webpack/modules-optimized/element-visibility";

clickEvent('.header-messages .close', false, function (event) {
    if (!localStorage) return;
    let date = new Date();
    localStorage.setItem('tvn_header_messages_close', date.getTime());
    slideUp(document.querySelector('.header-messages'));
    ga4TrackSimpleEvent(
        {
            event: 'loyalty',
            method: 'close'
        }
    );
});
import {changeEvent, customDocumentEvent, clickEvent} from "../../../../webpack/modules/events";
import {ga4TrackSimpleEvent} from "../../../../webpack/modules/analytics";
import {updateExclusivePremiumProducts} from "../../../../webpack/modules-optimized/product-list";
import {removeWishListItem, saveWishListItem, toggleWishlistItem} from "../../../../webpack/modules-optimized/wishlist";
import {closeLoadingModal, openLoadingModal, openModalFromHtml} from "../../../../webpack/modules-optimized/modal";
import {displayProductProductListImages, displayRoomProductListImages} from "../../../../webpack/modules-optimized/product-list";

customDocumentEvent('userInfoLoaded', function() {
    updateExclusivePremiumProducts(document.querySelector('#product-list-page #list-products'));
});

changeEvent('#product-list-page .image-display-selector', true, function(element) {
    let container = document.querySelector('#product-list-page');
    let listOfProducts = container.querySelector('#list-products');
    let listType = container.classList.contains('search-results') ? 'search_results' : 'product_list';
    let imageDisplay = element.value;
    let contentType = imageDisplay === 'product' ? 'product' : 'room';

    container.querySelectorAll(`.image-display-selector:not([value=${imageDisplay}])`).forEach((radio) => radio.checked = false);
    container.querySelectorAll(`.image-display-selector[value=${imageDisplay}]`).forEach((radio) => radio.checked = true);

    if (imageDisplay === 'product') displayProductProductListImages(listOfProducts);
    else if (imageDisplay === 'room') displayRoomProductListImages(listOfProducts);

    ga4TrackSimpleEvent({
        'event': listType,
        'method': 'product_vision',
        'content_type': contentType
    });
});

clickEvent('#product-list-page .add-item-wish-list', false, async function(element, event) {
    event.preventDefault();
    openLoadingModal();
    let productId = element.dataset.productId;
    let response = await toggleWishlistItem(productId);
    let error = !response.done;
    let popup = response.popup;
    let added = response.action === 'added';
    setTimeout(() => closeLoadingModal(), 400);

    if (error) {
        if (popup) openModalFromHtml(popup);
        window.facebookSignWithInit();
        google.accounts.id.renderButton(
            document.querySelector('#login-dialog').querySelector('.g_id_signin'),
            { type: "icon", size: "large", theme: "outline" }
        );
        return;
    }

    if (added) saveWishListItem(productId);
    else removeWishListItem(productId);
    updateWishListButton(element, added);

    ga4TrackSimpleEvent({
        event: element.classList.contains('from-search-results') ? 'search_results' : 'product_list',
        method: added ? 'add_to_wishlist' : 'remove_from_wishlist'
    });
});

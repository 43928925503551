//JS LIBRARIES
global.Modal = require('bootstrap/js/dist/modal');
global.Collapse = require('bootstrap/js/dist/collapse');
global.Offcanvas = require('bootstrap/js/dist/offcanvas');
const LazyLoad = require("vanilla-lazyload");
global.lazyLoad = new LazyLoad();
require('../../scripts-optimized/polyfills');
require('../../scripts/Tools/lazy-background-image');
require('../../scripts/Tools/webp-polyfill');

//JS
require('../../scripts-optimized/Whatsapp/main-critic');
require('../../scripts-optimized/MessageSOS/main-critic');
require('../../scripts-optimized/Cookies/main-critic');
require('../../scripts-optimized/Newsletter/main-critic');
require('../../scripts-optimized/Country/Popup/main-critic');
require('../../scripts-optimized/PopupSmarterClick/main-critic');
require('../../scripts-optimized/ProductFinder/main-critic');
require('../../scripts-optimized/Header/Messages/main-critic');
require('../../scripts-optimized/Header/Searcher/main-critic');
require('../../scripts-optimized/Header/Login/main-critic');
require('../../scripts-optimized/Header/Cart/main-critic');
require('../../scripts-optimized/Cart/General/main-critic');
require('../../scripts-optimized/Cart/Item/General/main-critic');
require('../../scripts-optimized/Cart/Item/Options/Size/main-critic');
require('../../scripts-optimized/Cart/Item/Options/GlassReversed/main-critic');
require('../../scripts-optimized/Cart/Item/Options/Pack/main-critic');
require('../../scripts-optimized/Cart/Item/Options/Color/main-critic');
require('../../scripts-optimized/Cart/Item/Options/CustomText/main-critic');
require('../../scripts-optimized/Cart/Item/Options/CustomImage/main-critic');
require('../../scripts-optimized/Cart/Item/Options/Tissue/main-critic');
require('../../scripts-optimized/Cart/Item/Options/TissueDrop/main-critic');
require('../../scripts-optimized/Cart/Item/Options/SupportColor/main-critic');
require('../../scripts-optimized/Cart/Item/Options/Model/main-critic');
require('../../scripts-optimized/Cart/Item/Options/MaterialColor/main-critic');
require('../../scripts-optimized/Cart/Item/Options/ModelSize/main-critic');
require('../../scripts-optimized/Cart/Item/ImageZoom/main-critic');
require('../../scripts-optimized/Cart/Voucher/main-critic');
require('../../scripts-optimized/Header/OuterMenu/main-critic');
require('../../scripts-optimized/Header/Breadcrumb/main-critic');
require('../../scripts-optimized/LeftMenu/main-critic');
require('../../scripts-optimized/Product/Size/main-critic');
require('../../scripts-optimized/Product/Color/main-critic');
require('../../scripts-optimized/Product/Pack/main-critic');
require('../../scripts-optimized/Product/CustomText/main-critic');
require('../../scripts-optimized/Product/CustomImage/main-critic');
require('../../scripts-optimized/Product/GlassReversed/main-critic');
require('../../scripts-optimized/Product/Tissue/main-critic');
require('../../scripts-optimized/Product/TissueDrop/main-critic');
require('../../scripts-optimized/Product/ChainPosition/main-critic');
require('../../scripts-optimized/Product/SupportColor/main-critic');
require('../../scripts-optimized/Product/ModelGender/main-critic');
require('../../scripts-optimized/Product/Model/main-critic');
require('../../scripts-optimized/Product/ModelSize/main-critic');
require('../../scripts-optimized/Product/MaterialColor/main-critic');
require('../../scripts-optimized/Product/Complement/main-critic');
require('../../scripts-optimized/Tools/Textarea/main-critic');
require('../../scripts-optimized/Tools/ScrollTopButton/main-critic');
require('../../scripts-optimized/Tools/PopupImageZoom/main-critic');

require('../../scripts-optimized/ProductList/Results/General/main-critic');
require('../../scripts-optimized/ProductList/Results/Reviews/main-critic');
require('../../scripts-optimized/ProductList/Results/Filters/main-critic');
require('../../scripts-optimized/ProductList/Results/Product/main-critic');

require('../../scripts-optimized/Footer/Contact/main-critic');
require('../../scripts-optimized/Footer/Newsletter/main-critic');
require('../../scripts-optimized/Footer/Social/main-critic');

//CSS

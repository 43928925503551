import {clickEvent} from "../../../webpack/modules/events";
import {slideDown, slideUp, slideUpBySelector} from "../../../webpack/modules/element-visibility";
import {post} from "../../../webpack/modules/ajax";

clickEvent('header .header-dropdown[data-type="user"]', false, async function(element) {
    let container = element.closest('.user');
    let dropdown = container.querySelector('.content');
    let formRendered = !dropdown.querySelector('.dropdown-loader');
    let isShowed = (dropdown.offsetWidth && dropdown.offsetHeight);

    if (!isShowed) {
        showDropdown(dropdown);
        if (!formRendered) {
            dropdown.innerHTML = await renderDropdown('user');
            window.facebookSignWithInit();
            google.accounts.id.renderButton(
                dropdown.querySelector('.g_id_signin'),
                { type: "icon", size: "large", theme: "outline" }
            );
        }
    }
    else hideDropdown(dropdown);
});

clickEvent('body', false, function(element, e){
    let target = e.target;
    let dropdown = document.querySelector('header .user .content');
    let isShowed = (dropdown.offsetWidth && dropdown.offsetHeight);

    if (isShowed && !target.classList.contains('user') && !target.closest("header .user")) {
        hideDropdown(dropdown);
    }
});

function renderDropdown(type)
{
    return new Promise(resolve => {
        post(
            '/ajax/website/header/render-dropdown',
            {
                type: type
            },
            function (response) {
                resolve(response.view);
            }
        );
    });
}

function showDropdown(dropdown)
{
    slideUpBySelector('.header-dropdown .content', 200, function() {
        slideDown(dropdown);
    });
}

function hideDropdown(dropdown)
{
    slideUp(dropdown);
}
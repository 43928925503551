import {onVisible} from "../../../../scripts/Tools/visible-element-observer";
import {ga4TrackSimpleEvent, itemDatalayerPush} from "../../../../webpack/modules/analytics";
import 'lazysizes';
import {changeEvent, clickEvent} from "../../../../webpack/modules/events";
import {toggleDescriptionVisibility} from "../../../../webpack/modules/description";

onVisible(document.querySelectorAll(".fire-product-datalayer"), (element) => itemDatalayerPush('view_item_list', element));
onVisible(document.querySelectorAll(".fire-promotion-datalayer"), function (element) {
    let id = element.dataset.promoId;
    let name = element.dataset.promoName;
    let creativeName = element.dataset.promoCreative;
    let position = element.dataset.position;
    if (id) {
        dataLayer.push({ecommerce: null});
        dataLayer.push({
            event: "view_promotion",
            ecommerce: {
                items: [
                    {
                        promotion_id: id,
                        promotion_name: name,
                        creative_name: creativeName,
                        creative_slot: position
                    }
                ]
            }
        });
    }
});

clickEvent('.description-container .change-description-visibility', true, function(button) {
    toggleDescriptionVisibility(button.closest('.description-container'));
});

changeEvent('#product-list-page .layout-selector', true, function(element) {
    changeLayout(element);
});

function changeLayout(filterSelected) {
    let products = document.querySelectorAll('#product-list-page #list-products .product-item');
    let colAdd = 'col-6';
    let colRemove = 'col-12';
    let listType = document.querySelector('#product-list-page.search-results') ? 'search_results' : 'product_list';

    if (filterSelected.value !== 'medium') {
        colAdd = 'col-12';
        colRemove = 'col-6';
    }

    let layouts = document.querySelectorAll('.layout-selector');
    layouts.forEach(function (layout) {
        if (layout.checked) layout.nextElementSibling.classList.add('selected');
        else layout.nextElementSibling.classList.remove('selected');
    });

    products.forEach(function (select) {
        select.classList.remove(colRemove);
        select.classList.add(colAdd)
    });
    ga4TrackSimpleEvent({
        'event': listType,
        'method': 'list_layout',
        'content_type': filterSelected.value === 'medium' ? 'two_columns' : 'one_column'
    });
}
import {clickEvent} from "../../../webpack/modules/events";
import {hideLoader, renderCart, showLoader} from "../../../webpack/modules-optimized/cart";

clickEvent('header .open-cart', false, async function() {
    let cart = document.querySelector('#cart');
    if (!cart.classList.contains('new-cart')) cart.classList.add('new-cart');
    let offcanvas = Offcanvas.getOrCreateInstance(cart);

    offcanvas.show();
    showLoader();
    await renderCart();
    hideLoader();
});